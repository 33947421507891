// const url = "http://127.0.0.1:5001/mphca-cca50/us-central1/";
const url = "https://mphca-cca50.web.app/";

export const sendContactEmail = async (from_name, email, company, message) => {
  try {
    const options = {
      method: "POST",

      body: JSON.stringify({
        from_name,
        email,
        company,
        message,
      }),
    };
    const response = await fetch(url + "emailMessage", options);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    console.log(responseData);
    return responseData;
  } catch (error) {
    console.error("Error:", error.message);
    throw error; // Propagate the error further if needed
  }
};
